
	import store from "../../../../common/services/store";
	import http from "../../../../common/services/http";
	import router from "../router";
	import { defineComponent, reactive, ref, onMounted, toRefs } from "vue";
	export default defineComponent({
		setup() {
			const result = ref({
				customer: {},
				product: {},
				details: {},
			});

			const { user } = toRefs(store.state);
			const costs = ref([]);

			const settings = ref({});

			function fetchCosts() {
				http.get(`/api/costs?fetch=all`).then((res) => {
					costs.value = res.data;
				});
			}

			function getCost(c: any) {
				return costs.value?.find((cost: any) => cost?.id == c);
			}

			onMounted(() => {
				http.get(
					`/api/calculations/${router.currentRoute.value.params.id}`
				).then((response) => {
					result.value = response.data;
				});

				http.get(`/api/settings`).then((res: any) => {
					settings.value = res.data;
				});

				fetchCosts();
			});

			return {
				user,
				result,
				getCost,
				settings,
			};
		},
	});
