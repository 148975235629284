<template>
	<div class="ng-content-wrap">
		<div class="ng-block-head ng-block-head-lg">
			<div class="ng-block-head-sub d-print-none">
				<a class="back-to" href="javascript:history.go(-1)">
					<em class="icon ni ni-chevron-left-c mr-1"></em>
					<span>Go Back</span>
				</a>
			</div>
			<div class="ng-block-between-md g-4">
				<div class="ng-block-head-content">
					<h2 class="ng-block-title fw-normal">
						{{ result.details?.title }}
					</h2>
					<div class="ng-block-des">
						<p>
							Calculated price of {{ result.product?.name }} for
							{{ result.user?.name }}
						</p>
					</div>
				</div>
				<div class="ng-block-head-content">
					<ul class="ng-block-tools gx-3 d-print-none">
						<li>
							<router-link
								to="/calculate"
								class="btn btn-primary btn-rounded m-v-10"
							>
								Calculate again
							</router-link>
						</li>
						<li>
							<router-link
								to="/history"
								data-toggle="modal"
								class="btn btn-white btn-dim btn-outline-primary"
							>
								<em class="icon ni ni-calendar"></em>
								<span>
									<span class="d-none d-sm-inline-block">
										View
									</span>
									History
								</span>
							</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="ng-block">
			<div class="row">
				<div class="col-md-6">
					<div class="ng-block">
						<div class="ng-block-head">
							<div class="ng-block-head-content">
								<h5 class="ng-block-title">
									Computed Result
								</h5>
								<div class="ng-block-des d-none">
									<p>
										Your personalized preference allows you
										best use.
									</p>
								</div>
							</div>
						</div>

						<div class="card card-bordered">
							<div class="card-content">
								<table class="table pmd-table table-orders">
									<thead class="tb-odr-head">
										<tr class="tb-odr-item">
											<th></th>

											<!-- <th
											class="text-right"
											v-if="
												result.user?.contact
													?.currency == 'euro'
											"
										>
											Price(€)
										</th> -->
											<th class="text-center">
												Price(KSh)
											</th>
											<th class="text-center">
												Price(£)
											</th>
											<!-- <th
											class="text-right"
											v-else-if="
												result.user?.contact
													?.currency == 'dollar'
											"
										>
											Price($)
										</th> -->
										</tr>
									</thead>

									<tbody
										class="tb-odr-body"
										v-if="settings.forex"
									>
										<tr class="tb-odr-item">
											<th>Per Kg</th>
											<td
												class="text-center font-weight-light font-size-20 m-b-0"
											>
												{{
													(result.details?.kgs).toFixed(
														2
													)
												}}
											</td>
											<td
												class="text-center font-weight-light font-size-20 m-b-0"
											>
												{{
													(
														result.details?.kgs /
														(settings?.forex?.rate[
															"pound"
														] || 1)
													).toFixed(2)
												}}
											</td>
										</tr>
										<tr>
											<th>Per Box</th>
											<td
												class="text-center font-weight-light font-size-20 m-b-0"
											>
												{{
													(
														result.details?.kgs *
														result.details
															?.box_weight
													).toFixed(2)
												}}
											</td>
											<td
												class="text-center font-weight-light font-size-20 m-b-0"
											>
												{{
													(
														(result.details?.kgs *
															result.details
																?.box_weight) /
														(settings?.forex?.rate[
															"pound"
														] || 1)
													).toFixed(2)
												}}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div class="ng-block">
						<div class="ng-block-head">
							<div class="ng-block-head-content">
								<h5 class="ng-block-title">
									Production Costs
								</h5>
								<div class="ng-block-des d-none">
									<p>
										Your personalized preference allows you
										best use.
									</p>
								</div>
							</div>
						</div>
						<div class="card card-bordered">
							<div class="card-content">
								<table class="table pmd-table table-orders">
									<thead class="tb-odr-head">
										<tr class="tb-odr-item">
											<th></th>
											<th class="text-right">
												Cost Per Kg
											</th>
										</tr>
									</thead>
									<tbody class="tb-odr-body">
										<tr class="tb-odr-item">
											<th>Raw Materials</th>
											<td class="text-right">
												KSh
												{{
													result.details
														?.raw_materials
												}}
											</td>
										</tr>
										<tr class="tb-odr-item">
											<th>Daily Wages</th>
											<td class="text-right">
												KSh
												{{
													result.details?.wages_costs
												}}
											</td>
										</tr>
										<tr class="tb-odr-item">
											<th>Fixed Costs</th>
											<td class="text-right">
												KSh
												{{
													result.details?.fixed_costs
												}}
											</td>
										</tr>
										<tr class="tb-odr-item">
											<th>Finance Costs</th>
											<td class="text-right">
												KSh
												{{ result.details?.finance }}
											</td>
										</tr>
									</tbody>
									<tfoot>
										<tr class="tb-odr-item">
											<th>Margin</th>
											<td class="text-right">
												{{ result.details?.margin }}%
											</td>
										</tr>
									</tfoot>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="ng-block">
						<div class="ng-block-head">
							<div class="ng-block-head-content">
								<h5 class="ng-block-title">
									Packaging Costs
								</h5>
								<div class="ng-block-des d-none">
									<p>
										Your personalized preference allows you
										best use.
									</p>
								</div>
							</div>
						</div>

						<div
							class="card card-bordered"
							v-if="result.details?.packaging_costs"
						>
							<div class="card-content">
								<table class="table pmd-table table-orders">
									<thead class="tb-odr-head">
										<tr class="tb-odr-item">
											<th></th>
											<th class="text-right">
												Item Cost
											</th>
											<th class="text-right">
												Cost Per Kg
											</th>
										</tr>
									</thead>
									<tbody class="tb-odr-body">
										<tr
											class="tb-odr-item"
											v-for="category in result.details
												?.packaging_costs"
											:key="category"
										>
											<th>
												{{ getCost(category)?.name }}
											</th>
											<td class="text-right">
												KSh
												<b>
													{{
														getCost(category)
															?.amount
													}}
												</b>
											</td>
											<td class="text-right">
												KSh
												<b>
													{{
														(result.details?.var_costs[
															category
														]).toFixed(2)
													}}
												</b>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div class="ng-block">
						<div class="ng-block-head">
							<div class="ng-block-head-content">
								<h5 class="ng-block-title">
									Packaging Summary
								</h5>
								<div class="ng-block-des d-none">
									<p>
										Your personalized preference allows you
										best use.
									</p>
								</div>
							</div>
						</div>

						<div class="card card-bordered">
							<div class="card-content">
								<table class="table">
									<tbody class="tb-odr-body">
										<tr class="tb-odr-item">
											<th>Pack Count</th>
											<td class="text-right">
												<b>
													{{
														result.details
															?.pack_count
													}}
												</b>
											</td>
										</tr>
										<tr class="tb-odr-item">
											<th>Pack Weight</th>
											<td class="text-right">
												<b>
													{{
														result.details
															?.pack_weight
													}}g
												</b>
											</td>
										</tr>
										<tr class="tb-odr-item">
											<th>Box Weight</th>
											<td class="text-right">
												<b>
													{{
														result.details
															?.box_weight
													}}kg
												</b>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import store from "../../../../common/services/store";
	import http from "../../../../common/services/http";
	import router from "../router";
	import { defineComponent, reactive, ref, onMounted, toRefs } from "vue";
	export default defineComponent({
		setup() {
			const result = ref({
				customer: {},
				product: {},
				details: {},
			});

			const { user } = toRefs(store.state);
			const costs = ref([]);

			const settings = ref({});

			function fetchCosts() {
				http.get(`/api/costs?fetch=all`).then((res) => {
					costs.value = res.data;
				});
			}

			function getCost(c: any) {
				return costs.value?.find((cost: any) => cost?.id == c);
			}

			onMounted(() => {
				http.get(
					`/api/calculations/${router.currentRoute.value.params.id}`
				).then((response) => {
					result.value = response.data;
				});

				http.get(`/api/settings`).then((res: any) => {
					settings.value = res.data;
				});

				fetchCosts();
			});

			return {
				user,
				result,
				getCost,
				settings,
			};
		},
	});
</script>
